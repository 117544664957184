import { Spin } from 'antd';
import styled from 'styled-components';

import { getMainLayout } from '../layouts/MainLayout';

import type { NextPageWithLayout } from './_app';
const SpinContainer = styled.div`
  align-items: center;
  display: grid;
`;
const Index: NextPageWithLayout = () => {
  return (
    <SpinContainer>
      <Spin />
    </SpinContainer>
  );
};

Index.getLayout = getMainLayout;

export default Index;
